<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :options.sync="options"
        :server-items-length="totalDesserts"
        :loading="loading"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tutup Buku</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialog()"> Tambah </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="" md="2">
                        <v-autocomplete
                          v-model="bulanSelected"
                          :items="bulans"
                          dense
                          item-text="text"
                          item-value="value"
                          outlined
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="" md="2">
                        <v-text-field type="number" v-model="tahun" dense outlined></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="close"> Batal </v-btn>
                  <v-btn color="primary" @click="save"> Simpan </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.aksi="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon>
        </template> -->
        <template v-slot:no-data>
          <v-btn color="primary" small @click="getDataFromApi"> Reload</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Tahun', value: 'tahun', sortable: false },
      { text: 'Bulan', value: 'bulan', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    editedIndex: -1,
    editedItem: {
      kode_anggota: 'auto',
      no_referensi: '',
      jabatan: '',
    },
    bulans: [
      {
        text: 'Januari',
        value: 1,
      },
      {
        text: 'Februari',
        value: 2,
      },
      {
        text: 'Maret',
        value: 3,
      },
      {
        text: 'April',
        value: 4,
      },
      {
        text: 'Mei',
        value: 5,
      },
      {
        text: 'Juni',
        value: 6,
      },
      {
        text: 'Juli',
        value: 7,
      },
      {
        text: 'Agustus',
        value: 8,
      },
      {
        text: 'September',
        value: 9,
      },
      {
        text: 'Oktober',
        value: 10,
      },
      {
        text: 'November',
        value: 11,
      },
      {
        text: 'Desember',
        value: 12,
      },
    ],
    bulanSelected: parseInt(new Date().toJSON().slice(5, 7)),
    tahun: parseInt(new Date().toJSON().slice(0, 4)),
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Bulan Penutupan' : 'Edit Bulan Penutupan'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    getDataFromApi() {
      this.loading = true
      const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('search', this.search)
      form.append('page', page)
      form.append('itemsPerPage', itemsPerPage)
      axios
        .post(`${apiRoot}/api/Jurnal/getTable_tutupbuku`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    openDialog() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
      } else {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('bulan', this.bulanSelected)
        form.append('tahun', this.tahun)
        axios
          .post(`${apiRoot}/api/Jurnal/addTutupbuku`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
  },
}
</script>
